/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef } from 'react';
import { GroupProps } from '@react-three/fiber';
import { ScreenSizes } from '@/types';
import useStore from '@/helpers/store';
import { LoopOnce} from 'three';
import { useSpring, animated, easings } from '@react-spring/three';
import { useGLTF, useAnimations } from '@react-three/drei';

interface RingModelProps extends GroupProps {
  screenSize?: ScreenSizes | undefined;
  isLargeDesktop?: boolean;
}

export default function Model({
  screenSize,
  isLargeDesktop,
  ...props
}: RingModelProps) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/Consensys_LootBox_GLB.glb');
  const { actions } = useAnimations(animations, group);

  const triggerLootBoxAnimation = useStore(
    (state) => state.triggerLootBoxAnimation
  );

  const convertToRadians = (val: number) => {
    return val * (Math.PI / 180);
  };

  useEffect(() => {
    if (actions && actions.animation_0) {
      actions.animation_0.loop = LoopOnce;
      actions.animation_0.clampWhenFinished = true;
      actions.animation_0.timeScale = 1.5;
      actions.animation_0.play();
      actions.animation_0.paused = true;
    }
  }, [actions]);

  useEffect(() => {
    if (triggerLootBoxAnimation && actions?.animation_0) {
      actions.animation_0.paused = false;
      actions.animation_0.play();
      console.log( (animations[0].duration / actions.animation_0.timeScale) * 1000);

      setTimeout(() => {
        document
          .getElementById('lootbox-canvas')
          ?.classList.add('animate-fadeout');
      }, (animations[0].duration / actions.animation_0.timeScale) * 1000 - (3000));

      setTimeout(() => {
        useStore.setState({ hideCanvas: true });
        useStore.setState({ triggerLootBoxAnimation: false });
      }, (animations[0].duration / actions.animation_0.timeScale) * 1000 - 2500);

      setTimeout(() => {
        useStore.setState({ lootboxAnimationFinished: true });
      }, (animations[0].duration / actions.animation_0.timeScale) * 1000 - (4000));
    }
  }, [triggerLootBoxAnimation, actions, animations]);

  const boxPosition = (): [x: number, y: number, z: number] => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return [0, 0, 0];
      case ScreenSizes.TABLET:
        return [0, 0, 0];
      default:
        return [1.5, 0, 0];
    }
  };
  
  const boxRotation = (): [x: number, y: number, z: number] => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return [0,0,0];
      case ScreenSizes.TABLET:
        return [0,0,0];
      default:
        return [
          convertToRadians(1),
          convertToRadians(-10),
          convertToRadians(1),
        ];
    }
  };

  const boxPositionAfterOpened = (): [x: number, y: number, z: number] => {
    return [0, 0, 0];
  };

  const { animatedBoxPosition } = useSpring({
    animatedBoxPosition: triggerLootBoxAnimation
      ? boxPositionAfterOpened()
      : boxPosition(),
    config: { duration: 1500, easing: easings.easeInOutQuad },
  });
  
  const { animatedBoxRotation } = useSpring({
    animatedBoxRotation: triggerLootBoxAnimation
      ? [
          convertToRadians(0),
          convertToRadians(0),
          convertToRadians(0),
        ]
      : boxRotation(),
    config: { duration: 1500, easing: easings.easeInOutQuad },
  });

  return (
    <animated.group 
      ref={group} 
      {...props} 
      dispose={null} 
      scale={0.01}
      // @ts-ignore
      rotation={
        triggerLootBoxAnimation ? animatedBoxRotation : boxRotation()
      }
      position={
        triggerLootBoxAnimation ? animatedBoxPosition : boxPosition()
      }
    >
      <group>
        <group name="Consensys_Box">
          <group name="Consensys_Box_Subgroup" rotation={[0.22, -1.42, 0.18]}>
            <group name="GodRays_2" position={[-248.27, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
              <group name="Cloner" rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
                <mesh 
                  name="Plane_1_0" 
                  // @ts-ignore
                  geometry={nodes.Plane_1_0.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_1" geometry={nodes.Plane_1_1.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_2" geometry={nodes.Plane_1_2.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_3" geometry={nodes.Plane_1_3.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_4" geometry={nodes.Plane_1_4.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_5" geometry={nodes.Plane_1_5.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_6" geometry={nodes.Plane_1_6.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_7" geometry={nodes.Plane_1_7.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_8" geometry={nodes.Plane_1_8.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_9" geometry={nodes.Plane_1_9.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_10" geometry={nodes.Plane_1_10.geometry} material={materials.Mat} position={[-36.22, 8.02, -245.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
              </group>
            </group>
            <group name="GodRays_3" position={[-252.37, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
              <group name="Cloner_2" rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
                <mesh 
                  // @ts-ignore
                  name="Plane_0" geometry={nodes.Plane_0.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1" geometry={nodes.Plane_1.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_2" geometry={nodes.Plane_2.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_3" geometry={nodes.Plane_3.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_4" geometry={nodes.Plane_4.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_5" geometry={nodes.Plane_5.geometry} material={materials.Mat} position={[-36.82, 8.15, -249.54]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
              </group>
            </group>
            <group name="GodRays" position={[-232.75, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
              <group name="Cloner_3" rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
                <mesh 
                  // @ts-ignore
                  name="Plane_1_0_2" geometry={nodes.Plane_1_0_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_1_2" geometry={nodes.Plane_1_1_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_2_2" geometry={nodes.Plane_1_2_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_3_2" geometry={nodes.Plane_1_3_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_4_2" geometry={nodes.Plane_1_4_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_5_2" geometry={nodes.Plane_1_5_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_6_2" geometry={nodes.Plane_1_6_2.geometry} material={materials.Mat} position={[-33.96, 7.52, -230.13]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
              </group>
            </group>
            <group name="GodRays_1" position={[-197.79, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
              <group name="Cloner_4" rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0}>
                <mesh 
                  // @ts-ignore
                  name="Plane_0_2" geometry={nodes.Plane_0_2.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_1_2_3" geometry={nodes.Plane_1_2_3.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_2_2" geometry={nodes.Plane_2_2.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_3_2" geometry={nodes.Plane_3_2.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_4_2" geometry={nodes.Plane_4_2.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_5_2" geometry={nodes.Plane_5_2.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_6" geometry={nodes.Plane_6.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_7" geometry={nodes.Plane_7.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
                <mesh 
                  // @ts-ignore
                  name="Plane_8" geometry={nodes.Plane_8.geometry} material={materials.Mat} position={[-28.86, 6.39, -195.57]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
                />
              </group>
            </group>
            <group name="Box_Front">
              <mesh 
                // @ts-ignore
                name="Concensys_Logo" geometry={nodes.Concensys_Logo.geometry} material={materials.Consensys_Decal} rotation={[Math.PI / 2, 0, 0]} scale={1.16} 
              />
              <mesh 
                // @ts-ignore
                name="Glowing" geometry={nodes.Glowing.geometry} material={materials.Emissive} position={[0, 49.85, 5.5]} rotation={[-0.34, 0.74, 0.23]} 
              />
              <mesh 
                // @ts-ignore
                name="Blue" geometry={nodes.Blue.geometry} material={materials['Blue.1']} position={[0, 0, 0.63]} rotation={[-0.34, 0.74, 0.23]} 
              />
            </group>
            <group name="Box_Rear">
              <mesh 
                // @ts-ignore
                name="Glowing_2" geometry={nodes.Glowing_2.geometry} material={materials.Emissive} position={[0, -49.85, -5.5]} rotation={[-0.34, 0.74, 0.23]} 
              />
              <mesh 
                // @ts-ignore
                name="Back" geometry={nodes.Back.geometry} material={materials['Blue.1']} position={[5.78, -19.67, -14.96]} rotation={[-0.34, 0.74, 0.23]} 
              />
            </group>
            <mesh  
              // @ts-ignore
              name="White_Glow" geometry={nodes.White_Glow.geometry} material={materials.PBR} position={[114.58, 0.03, 0.16]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
            />
            <mesh 
              // @ts-ignore
              name="colorExplode_1" geometry={nodes.colorExplode_1.geometry} material={materials['Mat.1']} position={[-94.39, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
            />
            <mesh 
              // @ts-ignore
              name="colorExplode" geometry={nodes.colorExplode.geometry} material={materials.PBR} position={[-51.35, 0, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} 
            />
            <mesh 
              // @ts-ignore
              name="Core" geometry={nodes.Core.geometry} material={materials.Emissive} 
            />
          </group>
        </group>
      </group>
    </animated.group>
  );
}

useGLTF.preload('/Consensys_LootBox_GLB.glb');
