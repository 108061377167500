import { Canvas } from '@react-three/fiber';
import {
  Environment,
  Html,
  PerspectiveCamera,
  useProgress,
} from '@react-three/drei';
import { Suspense, useEffect, useState } from 'react';
import ConsensysLootBoxModel from '@/components/ConsensysLootBoxModel';
import { findDeviceWidthSize, isLargeDesktop } from '@/utils';
import { ScreenSizes } from '@/types';
import SpinnerWithLogo from './icons/SpinnerWithLogo';

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <SpinnerWithLogo progress={progress} />
    </Html>
  );
}

const LootBoxCanvas = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizes>();
  const [largeDesktop, setIsLargeDesktop] = useState(false);

  useEffect(() => {
    const handleResizeEvent = () => {
      setScreenSize(findDeviceWidthSize());
      setIsLargeDesktop(isLargeDesktop());
    };

    handleResizeEvent();
    window.addEventListener('resize', handleResizeEvent);

    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  const convertToRadians = (val: number) => {
    return val * (Math.PI / 180);
  };

  const rotateZ = (): number => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return 0;
      case ScreenSizes.TABLET:
        return 0;
      default:
        return convertToRadians(0);
    }
  };

  const lootBoxCameraPosition = (): [x: number, y: number, z: number] => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return [0, -0.5, 7];
      case ScreenSizes.TABLET:
        return [0, -0.35, 4.5];
      case ScreenSizes.LARGE_DESKTOP:
        return [0, 0, 9];
      default:
        return [0, 0, 7];
    }
  };

  const lootBoxCameraRotation = (): [x: number, y: number, z: number] => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return [convertToRadians(-8.5), convertToRadians(0), rotateZ()];
      case ScreenSizes.TABLET:
        return [convertToRadians(-10), convertToRadians(0), rotateZ()];
      case ScreenSizes.LARGE_DESKTOP:
        return [0, 0, convertToRadians(0.5)];
      default:
        return [0, 0, convertToRadians(0.5)];
    }
  };

  const lootBoxRotation = (): [x: number, y: number, z: number] => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return [convertToRadians(15), 0, 0];
      case ScreenSizes.TABLET:
        return [convertToRadians(19), convertToRadians(11), 0];
      default:
        return [convertToRadians(11), convertToRadians(5), convertToRadians(0)];
    }
  };

  const lootBoxFov = (): number => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return 70;
      case ScreenSizes.TABLET:
        return 80;
      default:
        return 45;
    }
  };

  const lootBoxZoom = (): number => {
    switch (screenSize) {
      case ScreenSizes.MOBILE:
        return 1;
      case ScreenSizes.TABLET:
        return 1;
      default:
        return 1;
    }
  };

  return (
    <Canvas
      id="lootbox-canvas"
      mode="concurrent"
      style={{
        position: 'absolute',
        top: 0,
      }}
    >
      <Suspense fallback={<Loader />}>
        <ConsensysLootBoxModel
          isLargeDesktop={largeDesktop}
          screenSize={screenSize}
          rotation={lootBoxRotation()}
        />
        <PerspectiveCamera
          makeDefault
          rotation={lootBoxCameraRotation()}
          fov={lootBoxFov()}
          position={lootBoxCameraPosition()}
          zoom={lootBoxZoom()}
        />
        <ambientLight />
        <Environment path={'/'} files={'Tomoco-Studio_update.hdr'} />
      </Suspense>
    </Canvas>
  );
};

export default LootBoxCanvas;
