import create from 'zustand';

interface StoreState {
  triggerLootBoxAnimation: boolean;
  lootboxAnimationFinished: boolean;
  hideCanvas: boolean;
}

const useStore = create<StoreState>(() => {
  return {
    triggerLootBoxAnimation: false,
    lootboxAnimationFinished: false,
    hideCanvas: false,
  };
});

export default useStore;
