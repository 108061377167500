import ConsensysLogo from '@/components/icons/ConsensysLogo';
import Incentive from '@/components/Incentive';
import useAuth from '@/helpers/useAuth';
import { Link } from 'react-router-dom';

export const Incentives = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { token } = useAuth();

  return (
    <div className="relative flex items-center w-full text-white" {...props}>
      <div className="relative z-10 w-full h-full border-none loot-gradient backdrop-blur-lg desktop:backdrop-blur-xl desktop:max-w-7xl desktop:mx-auto desktop:rounded-lg desktop:mb-30 desktop:h-fit desktop:border">
        <div className="grid desktop:grid-cols-2 desktop:bg-gradient-border desktop:rounded-lg">
          <div className="px-8 pt-14 max-w-[31.25rem] mx-auto desktop:max-w-none desktop:px-24 desktop:pt-24 desktop:pl-28">
            <ConsensysLogo className="w-32 desktop:w-28" />
            <h1 className="mt-6 text-3xl tablet:text-[2.5rem] tablet:whitespace-nowrap desktop:whitespace-normal desktop:text-6xl">
              Start your Lootbox Journey!
            </h1>
            <p className="mt-4 tablet:mt-8 leading-[1.69rem]">
              Congratulations — you've started your lootbox journey. Click the link to access
              the Git repository where you will find some base application starters and a coding 
              challenge. After completing the challenge, document your developer journey, 
              click "Submit your Content" below and submit a link to your content.
            </p>
            <hr className="mt-8 border-white/[0.3]" />
            <div className="pt-8">
              <h1 className="text-3xl">Earn Rewards</h1>
              <p className="pt-4 leading-[1.69rem]">
                Get a free Consensys Loot Box by completing projects and
                documenting your developer journey in an article or video and sharing it on
                social media. Check out the Git repository to learn more.
              </p>
              <Link
                to={{ pathname: `/submit?token=${token}` }}
                className="flex mt-12 text-[1.125rem] uppercase text-white hover:text-gray-200"
              >
                Submit your Content
                <span className="ml-4">{'>'}</span>
              </Link>
            </div>
          </div>
          <div className="bg-white/[0.05] mt-12 desktop:mt-0 h-full w-full">
            <div className="flex flex-col px-8 py-12 max-w-[31.25rem] desktop:max-w-none mx-auto desktop:py-36 desktop:px-16 desktop:pr-26">
              <Incentive
                logo="tutorials"
                title="Tutorials"
                description="Quick start guides to get you on your way."
              />
              <Incentive
                className="mt-4 desktop:mt-8"
                logo="code"
                title="Code Samples"
                description="A collection of code snippets to start building your first web3 application using Infura technologies"
              />
              <Incentive
                className="mt-4 desktop:mt-8"
                logo="assets"
                title="Project Assets"
                description="Virtual goods and data files to create your web3 project and submit to social."
              />
              <div>
                <a href='https://github.com/INFURA/nft-api-lootbox-landing-page' target="_blank" rel="noreferrer">
                <button
                  className="w-full flex mx-auto px-8 desktop:px-26 py-[1.0625rem] desktop:w-auto mt-16 items-center text-sm leading-3.5 justify-center uppercase rounded-full bg-white text-black font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gray-200">
                  Github Repo
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Incentives;
