import ConsensysLogo from './ConsensysLogo';

interface Props {
  progress?: number;
}

const SpinnerWithLogo = ({ progress }: Props) => {
  return (
    <div className="absolute top-[-125px] left-[-125px] spinner-with-logo">
      <svg
        className="spinner"
        viewBox="0 0 157 157"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="78.5"
          cy="78.5"
          r="76"
          stroke="url(#paint0_angular_1645_2851)"
          strokeWidth="5"
        />
        <defs>
          <radialGradient
            id="paint0_angular_1645_2851"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(50, 0) rotate(90) scale(125)"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.1" />
          </radialGradient>
        </defs>
      </svg>
      <ConsensysLogo className="infura-logo relative left-[20px] top-[85px] w-52 desktop:left-[45px] desktop:top-[100px]" />
      <div className="progress-info relative left-[0] top-[95px] desktop:top-[115px] text-base text-center">
        {Math.floor(progress || 0)} {'%'} loaded
      </div>
    </div>
  );
};

export default SpinnerWithLogo;
