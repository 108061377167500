import RightArrow from '@/components/icons/RightArrow';
import { BaseError } from '@/types';
import Spinner from '@/components/icons/Spinner';

interface ProjectLinkFormProps extends React.HTMLAttributes<HTMLFormElement> {
  projectLink: string;
  setProjectLink: (email: string) => void;
  projectLinkError: BaseError | null;
  showLoader?: boolean;
  handleProjectLinkSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  projectLinkSubmitted?: boolean;
}

const ProjectLinkForm = ({
  projectLink,
  setProjectLink,
  projectLinkError,
  handleProjectLinkSubmit,
  showLoader,
  projectLinkSubmitted,
  ...props
}: ProjectLinkFormProps) => {
  const activeButtonStyles = () => {
    if (showLoader) {
      return '';
    }

    return projectLink.length > 0
      ? 'bg-white text-consensysBlue hover:bg-gray-200'
      : 'bg-white bg-opacity-30 text-black';
  };

  return (
    <form
      {...props}
      className="relative w-full pb-4 text-white group"
      onSubmit={handleProjectLinkSubmit}
    >
      <div className="relative flex items-center w-full h-12 max-w-lg border border-white rounded-full shadow-sm desktop:max-w-[620px] desktop:mx-auto focus-within:ring-1 focus-within:ring-white focus-within:border-white">
        <label htmlFor="project-url" className="sr-only">
          Paste your link here
        </label>
        <input
          type="url"
          name="project-url"
          id="project-url"
          className="block w-full ml-6 mr-2 text-sm leading-4 placeholder-white truncate bg-transparent border-0 content-box placeholder:text-sm placeholder:leading-4 placeholder:uppercase placeholder:opacity-60 focus:ring-0 focus:outline-none"
          placeholder="Paste your link here"
          aria-invalid="true"
          aria-describedby="project-url-error"
          onChange={(e) => setProjectLink(e.target.value)}
        />
        <button
          type="submit"
          disabled={projectLink.length === 0}
          className={`mr-1.5 flex shrink-0 items-center justify-center rounded-full h-9 w-9 focus:ring-0 focus:outline-none disabled:cursor-not-allowed
                  ${activeButtonStyles()}`}
        >
          {showLoader ? (
            <Spinner />
          ) : (
            <RightArrow className="w-5 h-5" aria-hidden="true" />
          )}
        </button>
      </div>
      {projectLinkSubmitted && (
        <p className="mt-1.5 ml-6 desktop:ml-0 text-sm">
          Thank you for your submission. Your link has been successfully
          submitted.
        </p>
      )}
      {projectLinkError && (
        <p
          className="mt-1.5 ml-6 desktop:ml-0 text-sm text-[#F76342]"
          id="project-url-error"
        >
          {projectLinkError?.reason || 'Please enter a valid project link'}
        </p>
      )}
    </form>
  );
};

export default ProjectLinkForm;
