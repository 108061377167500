import { axios, handleGenericErrorRespnse } from '@/services/http';
import { ResponseError } from '@/types';

const auth = async (token: string): Promise<ResponseError | undefined> => {
  try {
    await axios.post('/auth', { token });
    return;
  } catch (error) {
    return handleGenericErrorRespnse(error);
  }
};

export { auth };
