import React, { useState } from 'react';
import RightArrow from '@/components/icons/RightArrow';
import ProjectLinkForm from '@/components/ProjectLinkForm';

import BlobImage from '@/assets/images/consensys_blob.png';
import BlobImageMobile from '@/assets/images/consensys_blob_mobile.png';
import { Link } from 'react-router-dom';
import { submit } from '@/services/submit';
import useAuth from '@/helpers/useAuth';
import { BaseError } from '@/types';

interface FAQ {
  question: string;
  answer: string;
}

const SubmissionPage = () => {
  const { token } = useAuth();
  const [showLoader, setShowLoader] = useState(false);
  const [projectLink, setProjectLink] = useState('');
  const [projectLinkSubmitted, setProjectLinkSubmitted] = useState(false);
  const [projectLinkError, setProjectLinkError] = useState<BaseError | null>(
    null
  );

  const handleProjectFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowLoader(true);
    setProjectLinkError(null);
    setProjectLinkSubmitted(false);
    const error = await submit(token, projectLink);
    if (error) {
      setProjectLinkError(error.error);
    }
    setProjectLinkSubmitted(true);
    setShowLoader(false);
  };

  const faqs: FAQ[] = [
    {
      question: 'What kind of content should I produce?',
      answer:
        'An article or video that walks through your developer journey building your project. You should also include a link to the lootbox itself.',
    },
    {
      question: 'When will I receive my Infura loot?',
      answer:
        'Your entry will be manually reviewed for meeting criteria. You should receive an email within 7 days that will guide you through the process of taking receipt of your loot.',
    },
    {
      question: 'What kind of links are acceptable?',
      answer:
        'A permalink to a blog post, Youtube video, or other hosted content.',
    },
  ];

  return (
    <div className="relative flex w-full text-white animate-fade">
      <div className="fixed overflow-hidden">
        <img
          src={BlobImageMobile}
          alt=""
          className="fixed top-0 w-full h-full desktop:hidden"
        />
        <img
          src={BlobImage}
          alt=""
          className="fixed top-0 hidden w-full h-full desktop:block"
        />
      </div>
      <div className="fixed overflow-hidden">
      </div>
      <div className="relative z-10 block w-full mx-auto rounded-lg loot-gradient backdrop-blur-2xl desktop:max-w-7xl desktop:my-30 h-fit">
        <div className="w-full h-full px-6 desktop:rounded-lg py-14 tablet:px-0 desktop:px-10 tablet:py-20 tablet:max-w-[31.25rem] tablet:mx-auto desktop:max-w-none desktop:bg-gradient-border desktop:text-center">
          <Link
            to={{ pathname: `/claim?token=${token}` }}
            className="absolute inset-8 flex items-center justify-center bg-white text-black rounded-full h-9 w-9 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-[#ff582e]"
          >
            <RightArrow className="w-5 h-5 rotate-180" />
          </Link>

          <div className="w-full max-w-6xl mx-auto">
            <div className="flex flex-col-reverse justify-center w-full desktop:flex-row desktop:mt-26">
              <div className="flex-1 max-w-sm tablet:max-w-[31.25rem] desktop:max-w-sm text-left desktop:w-1/2">
                <h1 className="mb-6 text-4xl desktop:text-6xl">
                  Learn &amp; Earn
                </h1>
                <p className="mb-6">
                  Help us get the word out about the Infura NFT API and earn
                  rewards!
                </p>
                <p className="">
                  Complete one of the projects included in the Lootbox landing page and
                  share your experience on your Blog, Twitter or Youtube channel
                  and receive an exclusive Infura goodies.
                </p>
              </div>
            </div>
            <div className="mt-12 desktop:mt-20">
              <ProjectLinkForm
                projectLink={projectLink}
                setProjectLink={setProjectLink}
                projectLinkError={projectLinkError}
                showLoader={showLoader}
                handleProjectLinkSubmit={handleProjectFormSubmit}
                projectLinkSubmitted={projectLinkSubmitted}
              />
            </div>
          </div>
          <hr className="mb-14 mt-5 desktop:hidden border-white/[0.3]" />
          <div className="text-left desktop:px-30 desktop:mt-24 desktop:mb-20">
            <h1 className="mb-8 text-4xl desktop:text-6xl">FAQ</h1>
            <ul className="grid grid-cols-1 desktop:grid-cols-2 gap-x-12 gap-y-12 desktop:gap-y-26">
              {faqs.map((faq, index) => (
                <li
                  key={`${index}`}
                  className="max-w-sm tablet:max-w-[31.25rem] desktop:max-w-none"
                >
                  <p className="text-[1.125rem] desktop:text-[1.75rem] leading-[2.12rem]">
                    {faq.question}
                  </p>
                  <p className="mt-4 opacity-[0.7] desktop:mt-8">
                    {faq.answer}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionPage;
