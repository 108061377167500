interface Props {
  className?: string;
}

const Spinner = ({ className }: Props) => {
  const classNames = `${className} w-9 h-9 spinner`;

  return (
    <svg
      className={classNames}
      viewBox="0 0 157 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="78.5"
        cy="78.5"
        r="76"
        stroke="url(#paint0_angular_1645_2851)"
        strokeWidth="5"
      />
      <defs>
        <radialGradient
          id="paint0_angular_1645_2851"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50, 0) rotate(90) scale(125)"
        >
          <stop offset="0" stopColor="#FFF" />
          <stop offset="1" stopColor="#FFF" stopOpacity="0.1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Spinner;
