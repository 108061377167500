import HeroContentLayout from '@/components/layout/HeroContentLayout';
import LootBoxCanvas from '@/components/LootBoxCanvas';
import Incentives from '@/components/Incentives';
import BlobImage from '@/assets/images/consensys_blob.png';
import BlobImageMobile from '@/assets/images/consensys_blob_mobile.png';
import useStore from '@/helpers/store';

const ClaimPage = () => {
  const lootboxAnimationFinished = useStore(
    (state) => state.lootboxAnimationFinished
  );
  const hideCanvas = useStore((state) => state.hideCanvas);
  const handleOpenLootBox = () => {
    useStore.setState({ triggerLootBoxAnimation: true });
  };

  return (
    <div className="text-white">
      {!hideCanvas && <LootBoxCanvas />}
      <HeroContentLayout>
        <h1 className="hero-heading">
          Open your <br className="desktop:block tablet:hidden" /> Loot Box!
        </h1>
        <p className="hero-lead-text">
          You’re a click away. Ready for tutorials, code samples, NFTS, and other goodies? Click the button below to get into your Consensys Loot Box.
        </p>
        <button
          onClick={handleOpenLootBox}
          type="button"
          id="open-lootbox"
          className="flex px-12 py-[1.0625rem] items-center text-sm leading-3.5 justify-center uppercase rounded-full bg-white text-consensysBlue hover:bg-gray-200 hover:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-offset-consensysBlue hover:ring-white"
        >
          UNLOCK MY LOOT
        </button>
      </HeroContentLayout>
      {lootboxAnimationFinished && (
        <div className={`${!hideCanvas ? 'animate-fadein' : ''}`}>
          <img
            src={BlobImageMobile}
            alt=""
            className="fixed top-0 w-full h-full desktop:hidden"
          />
          <img
            src={BlobImage}
            alt=""
            className="fixed top-0 hidden w-full h-full desktop:block"
          />
          <div className="absolute top-0 left-0 right-0">
            <div className='desktop:h-30' />
            <Incentives />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimPage;
