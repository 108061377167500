export interface BaseError {
  name?: string;
  status?: number;
  reason?: string;
}

export interface ResponseError {
  error: BaseError;
}

export enum ScreenSizes {
  MOBILE,
  TABLET,
  DESKTOP,
  LARGE_DESKTOP,
}

export type ScreenSize = keyof typeof ScreenSizes;

export const MAX_MOBILE_SCREEN_WIDTH = 767;
export const MAX_TABLET_SCREEN_WIDTH = 1439;
export const MAX_DESKTOP_SCREEN_WIDTH = 1919;
export const SHORT_DESKTOP_HEIGHT = 1200;

export interface DownloadResponse {
  fileBuffer: Buffer;
}
