import { AuthContext, AuthProps } from '@/helpers/auth-context';
import { useEffect, useState } from 'react';
import { auth } from '@/services/auth';
import useAuth from '@/helpers/useAuth';

interface Props {
  children?: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const [context, setContext] = useState<AuthProps | null>(null);
  const { token } = useAuth();

  useEffect(() => {
    if (context) {
      return;
    }

    const authCheck = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      let token = queryParams.get('token') || '';
      if (token) {
        const error = await auth(token);
        token = error ? '' : token;
      }

      setContext({
        token,
      });
    };

    authCheck();
  }, [context, token]);

  return (
    context && (
      <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    )
  );
};

export default AuthProvider;
