import ConsensysLogoImage from '@/assets/images/Consensys_Logo.png';

interface ConsensysLogoProps {
  className?: string;
}
const ConsensysLogo = ({className}: ConsensysLogoProps) => {
  return (
    <img
      src={ConsensysLogoImage}
      alt=""
      className={className}
    />
  );
};

export default ConsensysLogo;
