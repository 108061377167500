import ConsensysLogo from '@/components/icons/ConsensysLogo';
import useStore from '@/helpers/store';

interface LandingPageProps {
  children?: React.ReactNode;
  slideOut?: boolean;
  emailSubmitted?: boolean;
}

const LandingPage = ({ children, emailSubmitted }: LandingPageProps) => {
  const triggerLootBoxAnimation = useStore(
    (state) => state.triggerLootBoxAnimation
  );
  return (
    <div
      className={`relative flex flex-col justify-between h-screen pb-24 mx-auto short:pb-14 desktop:min-h-full desktop:grid desktop:pb-0 desktop:auto-rows-min desktop:grid-cols-8 desktop:px-26 max-w-8xl ${
        emailSubmitted
          ? 'min-h-[110vh] short:min-h-[115vh] tablet:min-h-[105vh]'
          : ''
      } ${
        triggerLootBoxAnimation
          ? '-translate-x-full duration-[750ms] ease-in'
          : ''
      }`}
    >
      <div className="desktop:hidden">
        <div className="flex justify-center mt-14 tablet:mb-4">
          <ConsensysLogo className="h-[50px] tablet:h-[70px]" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center order-last text-center col-span-full desktop:col-span-3 desktop:order-first desktop:h-screen desktop:items-start desktop:text-left">
        <div className="hidden desktop:block">
          <div className="mb-10">
            <ConsensysLogo />
          </div>
        </div>
        {children}
      </div>
      <footer className="absolute transform -translate-x-1/2 bottom-6 left-1/2 text-xs text-[#999999] flex gap-10 whitespace-nowrap">
        <a
          className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-[#ff582e] rounded-lg px-1"
          href="https://consensys.net/terms-of-use"
        >
          Terms of Use
        </a>
        <a
          className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-[#ff582e] rounded-lg px-1"
          href="https://consensys.net/privacy-policy"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default LandingPage;
