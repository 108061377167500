import RightArrow from './icons/RightArrow';
import { BaseError } from '@/types';
import Spinner from './icons/Spinner';

interface EmailFormProps {
  email: string;
  setEmail: (email: string) => void;
  emailError: BaseError | null;
  showLoader?: boolean;
  handleEmailSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const EmailForm = ({
  email,
  setEmail,
  emailError,
  showLoader,
  handleEmailSubmit,
}: EmailFormProps) => {
  const activeButtonStyles = () => {
    if (showLoader) {
      return '';
    }

    return email.length > 0
      ? 'bg-white text-consensysBlue hover:bg-gray-200'
      : 'bg-white bg-opacity-30 text-black';
  };

  return (
    <form
      className="relative w-full px-6 text-white group desktop:px-0"
      onSubmit={handleEmailSubmit}
    >
      <div className="relative flex items-center w-full h-12 max-w-lg mx-auto border border-white rounded-full shadow-sm desktop:w-74 desktop:mx-0 focus-within:ring-1 focus-within:ring-white focus-within:border-white">
        <label
          htmlFor="email"
          className="absolute inline-block px-1 -mt-px font-medium uppercase bg-consensysBlue text-xxs -top-2 left-5"
        >
          Email Address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full ml-6 text-sm placeholder-white bg-transparent border-0 desktop:text-base content-box placeholder:opacity-60 focus:ring-0 focus:outline-none"
          placeholder="Enter your email address"
          aria-invalid="true"
          aria-describedby="email-error"
          onChange={(e) => setEmail(e.target.value)}
          // TODO: remove this (1password was annoying me lol)
          autoComplete="off"
        />
        <button
          type="submit"
          formNoValidate
          disabled={email.length === 0}
          className={`mr-1.5 flex shrink-0 items-center justify-center rounded-full h-9 w-9 focus:ring-0 focus:outline-none disabled:cursor-not-allowed
                  ${activeButtonStyles()}`}
        >
          {showLoader ? (
            <Spinner />
          ) : (
            <RightArrow className="w-5 h-5" aria-hidden="true" />
          )}
        </button>
      </div>
      {emailError && (
        <p className="mt-1.5 ml-6 text-sm text-[#F76342]" id="email-error">
          {emailError?.reason || 'Please enter a valid email address'}
        </p>
      )}
    </form>
  );
};

export default EmailForm;
