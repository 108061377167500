import AssetsImage from '@/assets/images/assets.svg';
import CodeImage from '@/assets/images/code.svg';
import TutorialsImage from '@/assets/images/tutorials.svg';

export interface IncentiveProps {
  className?: string;
  logo: 'tutorials' | 'code' | 'assets';
  title: string;
  description: string;
}

const Incentive = ({ className = '', logo, title, description }: IncentiveProps) => {
  const images = {
    assets: AssetsImage,
    code: CodeImage,
    tutorials: TutorialsImage,
  };
  
  return (
    <div className={`px-6 py-4 desktop:py-8 rounded-xl bg-white/[0.18] ${className}`}>
      <div className='flex'>
        <img src={images[logo]} alt="" className='mr-4 desktop:mr-10' />
        <div className='flex flex-col justify-center'>
          <p className="text-[0.875rem] tablet:text-[1.25rem] font-bold">{title}</p>
          <p className="opacity-[0.7] text-[0.75rem] desktop:text-[1.125rem] leading-[1.1rem]">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Incentive;
