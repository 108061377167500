import { axios, handleGenericErrorRespnse } from '@/services/http';
import { ResponseError } from '@/types';

const register = async (email: string): Promise<ResponseError | undefined> => {
  try {
    await axios.post('/register', { email });
    return;
  } catch (error) {
    return handleGenericErrorRespnse(error);
  }
};

export { register };
