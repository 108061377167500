import { axios, handleGenericErrorRespnse } from '@/services/http';
import { ResponseError } from '@/types';

const submit = async (
  token: string,
  submissionUrl: string
): Promise<ResponseError | undefined> => {
  try {
    await axios.post('/submit', { token, submissionUrl });
    return;
  } catch (error) {
    return handleGenericErrorRespnse(error);
  }
};

export { submit };
