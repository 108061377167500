import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import HeroContentLayout from '@/components/layout/HeroContentLayout';
import { isValidEmail } from '@/utils';
import EmailSubmitted from '@/components/EmailSubmitted';
import EmailForm from '@/components/EmailForm';
import LootBoxCanvas from '@/components/LootBoxCanvas';
import { register } from '@/services/register';
import { BaseError } from '@/types';
import useAuth from '@/helpers/useAuth';

const RegisterPage = () => {
  const { token } = useAuth();
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [emailError, setEmailError] = useState<BaseError | null>(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError(null);
    setShowLoader(true);

    if (!isValidEmail(email)) {
      setShowLoader(false);
      setEmailError({
        name: 'InvalidEmail',
        reason: 'Please enter a valid email address',
        status: 400,
      });
      return;
    }
    await sendEmail();
  };

  const sendEmail = async () => {
    setShowLoader(true);
    const error = await register(email);
    if (error) {
      setEmailSubmitted(false);
      setEmailError(error.error);
    } else {
      setEmailSubmitted(true);
    }

    setShowLoader(false);
  };

  if (token) {
    return <Navigate to={`/claim?token=${token}`} replace />;
  }

  return (
    <div className="text-white">
      <LootBoxCanvas />
      <HeroContentLayout emailSubmitted={emailSubmitted}>
        <h1 className="hero-heading">
          You've scored a <br className="tablet:hidden desktop:block" /> Loot
          Box!
        </h1>
        <p className="hero-lead-text">
          Congratulations—find all the Loot you need to explore the metaverse. Sign up with your email address to get access your Loot.
        </p>
        {emailSubmitted ? (
          <EmailSubmitted
            email={email}
            handleEmailSubmit={sendEmail}
            showLoader={showLoader}
          />
        ) : (
          <EmailForm
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            showLoader={showLoader}
            handleEmailSubmit={handleEmailSubmit}
          />
        )}
      </HeroContentLayout>
    </div>
  );
};

export default RegisterPage;
