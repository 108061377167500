interface EmailSubmittedProps {
  email: string;
  showLoader?: boolean;
  handleEmailSubmit: () => Promise<void>;
}

const EmailSubmitted = ({
  email,
  showLoader,
  handleEmailSubmit,
}: EmailSubmittedProps) => {
  const buttonActiveStateStyles = () => {
    return showLoader ? 'text-slate-500' : 'text-consensysBlue';
  };

  return (
    <div className="max-w-lg p-6 mx-6 text-xs rounded-lg email-submitted-gradient tablet:text-base desktop:text-lg tablet:mx-0 desktop:max-w-none">
      <p className="mb-6">You're almost in!</p>
      <p className="mb-6">
        Look for a link in your email -{' '}
        <span className="font-semibold tracking-wide">{email}</span> - to get
        access to your LootBox. Remember to check your spam folder if you cannot
        locate the email we sent.
      </p>
      <p>
        Still can’t find your email?{' '}
        <button
          type="button"
          onClick={handleEmailSubmit}
          className={`ml-2 font-semibold tracking-wide transition-all duration-150 ease-in-out ${buttonActiveStateStyles()}`}
          disabled={showLoader}
        >
          Resend email
        </button>
      </p>
    </div>
  );
};

export default EmailSubmitted;
