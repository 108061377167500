import axios, { AxiosError } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;

const handleGenericErrorRespnse = (error: unknown | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const err = error as AxiosError;
    const { response } = err;
    if (response?.data && response.data.error) {
      return response?.data;
    }

    return {
      error: {
        status: response?.status,
        name: 'Unkown',
        reason: response?.statusText,
      },
    };
  } else {
    const err = error as Error;
    return {
      error: {
        reason: err.message,
        name: err.name,
        status: 400,
      },
    };
  }
};

export * from 'axios';
export { axios, handleGenericErrorRespnse };
