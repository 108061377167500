import {
  ScreenSizes,
  MAX_MOBILE_SCREEN_WIDTH,
  MAX_TABLET_SCREEN_WIDTH,
  SHORT_DESKTOP_HEIGHT,
} from '@/types';

export const isValidEmail = (email: string) => {
  const emailValidationRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailValidationRegex.test(String(email).toLowerCase());
};

export const findDeviceWidthSize = () => {
  if (window.innerWidth <= MAX_MOBILE_SCREEN_WIDTH) {
    return ScreenSizes.MOBILE;
  } else if (window.innerWidth <= MAX_TABLET_SCREEN_WIDTH) {
    return ScreenSizes.TABLET;
  } else {
    if (isLargeDesktop()) {
      return ScreenSizes.LARGE_DESKTOP;
    }
    return ScreenSizes.DESKTOP;
  }
};

export const isLargeDesktop = () => {
  return window.innerHeight > SHORT_DESKTOP_HEIGHT;
};
